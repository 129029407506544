import "./sliderStyle.css";
import docker from "../../images/logos/docker-original.svg";
import dotnet from "../../images/logos/dot-net-original.svg";
import figma from "../../images/logos/figma-original.svg";
import git from "../../images/logos/git-original-wordmark.svg";
import github from "../../images/logos/github-original-wordmark.svg";
import sqlserver from "../../images/logos/microsoftsqlserver-plain-wordmark.svg";
import react from "../../images/logos/react-original.svg";

function Slider() {
  const list = [git, figma, react, dotnet, docker, github, sqlserver];

  const elements = list.map((e) => (
    <div key={e} className="icon-border">
      <img key={e} src={e} alt="error" className="icon" />
    </div>
  ));

  return (
    <div className="slider">
      <div className="background">{elements}</div>
    </div>
  );
}

export default Slider;
