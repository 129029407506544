import logo from "../../images/my_photo.jpg";
import "./headerStyle.css";

function Header() {
  return (
    <header>
      <img src={logo} className="myPhoto" />
      <div className="link-container">
        <a href="https://github.com/dominik-gasior" className="link">
          Blog
        </a>
        <a href="https://www.linkedin.com/in/dominikgasior/" className="link">
          About me
        </a>
        <a href="https://www.linkedin.com/in/dominikgasior/" className="link">
          Contact
        </a>
      </div>
    </header>
  );
}

export default Header;
