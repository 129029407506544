import "./welcomeStyle.css";

function Welcome() {
  return (
    <div className="welcome">
      <h1>Hello!</h1>
      <h2>My name is Dominik</h2>
      <h3>I'm .NET developer</h3>
    </div>
  );
}

export default Welcome;
