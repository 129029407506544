import Header from "../hooks/header/headerComponent";
import Slider from "../hooks/slider/sliderComponent";
import Welcome from "../hooks/welcome/welcomeComponent";

function Home() {
  return (
    <div className="app">
      <Header />
      <Welcome />
      <Slider />
    </div>
  );
}

export default Home;
